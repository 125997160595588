import { BUTTON_VARIANT } from "@elliemae/ds-button";
import { Grid } from "@elliemae/ds-grid";
import { DSSeparatorV2 } from "@elliemae/ds-separator";
import FooterButtons from "components/FooterButtons";
import { closeLoader } from "data/screenLoader/actions";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lockExtensionService from "services/lockExtensionServices";
import {
  StyledEppsContainerBody,
  StyledEppsParentView,
  StyledEppsWrapper,
} from "view/global/styles/styled";
import { lockExtentionAction } from "data/lockExtension/actionCreators";
import { openGlobalErrorModalAction } from "data/globalErrorModal/actionCreators";
import Common from "common/services/Common";
import AdjustmentTableSectionWrapper from "./adjustmentTableSectionWrapper";
import CurrentLockDetails from "./currentLockDetails";
import CalculatedExtensionDetails from "./calculatedExtensionDetails";
import ExtensionForm from "./extensionForm";
import LoanFieldValidationModal from "./loanFieldValidationModal";

const canCalculateExtension = ({
  numberOfDays,
  parentLockUuid,
  extensionFormOptions,
}) =>
  Boolean(
    numberOfDays &&
      parentLockUuid &&
      numberOfDays <= extensionFormOptions.maxNumberOfDays
  );

const getParentLockUuid = (lockDetails) =>
  lockDetails?.currentLockRequest?.id ?? lockDetails?.originalLockRequest?.id;

const LockExtension = () => {
  const dispatch = useDispatch();
  const rootState = useSelector((state) => state);
  const [extensionLockDays, setExtensionLockDays] = useState(0);
  const [adjustmentOverride, setAdjustmentOverride] = useState(0);
  const [comments, setComments] = useState("");
  const [extendRequest, setExtendRequest] = useState(null);

  useEffect(() => {
    if (
      extendRequest &&
      extensionLockDays <= extensionFormOptions.maxNumberOfDays
    ) {
      // setComments runs asyncronously, and needs to wait for state to update to update
      // is there a better way to assign a click handler, that references useState data, that may not be updated yet?
      dispatch(
        lockExtentionAction({
          comments,
          daysToExtend: extensionLockDays,
          lockExtendPriceAdjustment:
            lockDateCalculation.priceAdjustment ||
            -Math.abs(adjustmentOverride),
        })
      );
    }
  }, [extendRequest]);

  const getLockData = async (state) => {
    const response = {
      rateSheetID:
        state?.EPPS?.originalLoanInformation?.rateLock?.currentRateSheetId,
      lockValidationStatus:
        state?.EPPS?.originalLoanInformation?.rateLock?.lockValidationStatus,
      lockDate: state?.EPPS?.extensionData?.buySideLockDate,
      lockExpirationDate:
        state?.EPPS?.extensionData?.buySideExtendedLockExpires ??
        state?.EPPS?.originalLoanInformation?.rateLock?.buySideLockExpires,
      totalRateRequested: state?.EPPS?.loanInfo?.loanInformation?.target,
      rateAdjustments: (state?.EPPS?.extensionData?.currentAdjustments ?? [])
        .filter((ad) => ad.priceAdjustmentType === "BaseRate")
        .map((ad) => ({
          description: ad.description,
          amount: ad.rate,
        })),
      priceAdjustments: (state?.EPPS?.extensionData?.currentAdjustments ?? [])
        .filter((ad) => ad.priceAdjustmentType === "BasePrice")
        .map((ad) => ({
          description: ad.description,
          amount: ad.rate,
        })),
      totalPriceRequested:
        state?.EPPS?.extensionData?.currentPriceRateRequested,
      baseRate: state?.EPPS?.loanInfo?.loanInformation?.target,
      basePrice: state?.EPPS?.extensionData?.profitMarginAdjustedBuyPrice,
    };

    const allLockRequests = (
      rootState?.EPPS?.originalLoanInformation?.lockRequests ?? []
    ).sort((a, b) => new Date(a.requestedDate) - new Date(b.requestedDate));
    const originalLockRequest = allLockRequests?.[0];
    const currentLockRequest =
      allLockRequests?.length > 1
        ? allLockRequests?.[allLockRequests.length - 1]
        : null;

    response.allLockRequests = allLockRequests;

    response.originalLockRequest = {
      id: originalLockRequest?.id,
      requestedDate: originalLockRequest?.snapshotFields?.find(
        ({ fieldId }) => fieldId === "2149"
      )?.value,
      fulfilledDate: originalLockRequest?.snapshotFields?.find(
        ({ fieldId }) => fieldId === "2592"
      )?.value,
      numberOfDays:
        state?.EPPS?.originalLoanInformation?.rateLock?.buySideNumberOfDays ??
        state?.EPPS?.originalLoanInformation?.rateLock?.requestNumberOfDays,
      expiresDate: originalLockRequest?.snapshotFields?.find(
        ({ fieldId }) => fieldId === "2091"
      )?.value,
      cumulatedDaystoExtend: null,
    };

    response.currentLockRequest = !currentLockRequest
      ? null
      : {
          id: currentLockRequest?.id,
          requestedDate: currentLockRequest?.snapshotFields?.find(
            ({ fieldId }) => fieldId === "2149"
          )?.value,
          fulfilledDate: null,
          numberOfDays: currentLockRequest?.buySideNumDayExtended,
          expiresDate:
            state?.EPPS?.originalLoanInformation?.rateLock
              ?.buySideExtendedLockExpires,
          cumulatedDaystoExtend: currentLockRequest?.cumulatedDaystoExtend,
        };

    return response;
  };
  const [lockDetails, setLockDetails] = useState({
    rateSheetID: "",
    lockDate: "",
    lockExpirationDate: "",
    totalRateRequested: "",
    rateAdjustments: [],
    priceAdjustments: [],
    totalPriceRequested: "",
    baseRate: "",
    basePrice: "",
  });

  const [extensionFormOptions, setExtensionFormOptions] = useState(null);

  useEffect(async () => {
    if (!lockDetails?.allLockRequests?.length) {
      return;
    }

    const {
      getControlSettings,
    } = await lockExtensionService.getLockPageSettings();

    const settings = getControlSettings({
      lockRequests: lockDetails?.allLockRequests ?? [],
      originalLockRequest: lockDetails?.originalLockRequest,
      currentLockRequest: lockDetails?.currentLockRequest,
    });

    setExtensionFormOptions(settings);
  }, [lockDetails]);

  const [lockDateCalculation, setLockDateCalculation] = useState({
    lockExpirationDate: "",
    numberOfDays: 0,
    priceAdjustment: 0,
  });

  useEffect(() => {
    const numberOfDays = Number(extensionLockDays);
    const parentLockUuid = getParentLockUuid(lockDetails);

    if (
      canCalculateExtension({
        numberOfDays,
        parentLockUuid,
        extensionFormOptions,
      })
    ) {
      lockExtensionService
        .calculateRateLockExtension(rootState.EPPS.loanInfo.customId, {
          numberOfDays,
          lockDate: lockDetails?.lockExpirationDate,
          parentLockUuid,
        })
        .then((response) =>
          setLockDateCalculation({
            ...response,
            priceAdjustment:
              -Math.abs(adjustmentOverride) || response.priceAdjustment,
          })
        );
    }
  }, [
    lockDetails,
    extensionLockDays,
    adjustmentOverride,
    extensionFormOptions,
  ]);

  useEffect(() => {
    const showError = ({ title, errorMessage }) =>
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: Common.closeApplication,
          title,
          errorMessage,
          confirmLabel: "Exit ICE PPE",
        })
      );

    const totalExtensions = lockDetails?.allLockRequests?.length;
    const hasRequestedRequests = lockDetails?.allLockRequests?.some(
      (i) => i.requestedStatus === "Requested"
    );
    if (
      extensionFormOptions?.totalExtensionsAllowed &&
      totalExtensions &&
      totalExtensions > extensionFormOptions.totalExtensionsAllowed
    ) {
      return showError({
        title: "Lock Extension Limit Reached",
        errorMessage: `Loan cannot be extended. Loan ${
          rootState.EPPS.loanInfo.loanInformation.loanNumber ??
          rootState.EPPS.loanInfo.loanInformation.loanId
        } has already been extended its max number of times (${
          extensionFormOptions.totalExtensionsAllowed
        }).`,
      });
    }

    if (hasRequestedRequests) {
      return showError({
        title: "Pending Pricing Request",
        errorMessage: `Loan ${
          rootState.EPPS.loanInfo.loanInformation.loanNumber ??
          rootState.EPPS.loanInfo.loanInformation.loanId
        } currently has a pending pricing request. Retry after request has been completed.`,
      });
    }

    if (typeof extensionFormOptions?.maxNumberOfDays === "number") {
      const noEnumeratedOptions =
        extensionFormOptions?.daysToExtendControlType === "Enumerated" &&
        !extensionFormOptions.daysToExtendControlOptions.length;
      const noTextOptions =
        extensionFormOptions?.daysToExtendControlType === "Text" &&
        !extensionFormOptions.maxNumberOfDays;
      if (noEnumeratedOptions || noTextOptions) {
        return showError({
          title: "Max Extension Reached",
          errorMessage: `Loan ${
            rootState.EPPS.loanInfo.loanInformation.loanNumber ??
            rootState.EPPS.loanInfo.loanInformation.loanId
          } has reached the maximum number of extension days`,
        });
      }
    }

    return null;
  }, [extensionFormOptions, lockDetails]);

  const canExtendLock = useMemo(() => {
    const response =
      Boolean(
        lockDateCalculation?.lockExpirationDate &&
          lockDateCalculation?.numberOfDays &&
          typeof lockDateCalculation?.priceAdjustment === "number"
      ) &&
      canCalculateExtension({
        numberOfDays: Number(extensionLockDays),
        parentLockUuid: getParentLockUuid(lockDetails),
        extensionFormOptions,
      });

    return response;
  }, [
    lockDateCalculation,
    extensionLockDays,
    extensionFormOptions,
    lockDetails,
  ]);

  const footerButtons = useMemo(
    () => [
      {
        id: "cancel",
        type: "secondary",
        text: "Cancel",
        variant: BUTTON_VARIANT.DEFAULT,
        disabled: false,
        onClick: Common.closeApplication,
      },
      {
        id: "request-extension",
        type: "primary",
        text: "Request Extension",
        variant: BUTTON_VARIANT.DEFAULT,
        disabled: !canExtendLock,
        size: "m",
        onClick: setExtendRequest,
      },
    ],
    [canExtendLock]
  );

  const initPage = async () => {
    dispatch(closeLoader());
    setLockDetails(await getLockData(rootState));
  };

  useEffect(() => {
    initPage();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StyledEppsParentView>
        <StyledEppsContainerBody>
          <StyledEppsWrapper>
            <LoanFieldValidationModal
              lockValidationStatus={lockDetails.lockValidationStatus}
            />
            <Grid>
              <CurrentLockDetails lockDetails={lockDetails} />
            </Grid>

            <DSSeparatorV2 m="m 0" isDotted />

            <Grid>
              <AdjustmentTableSectionWrapper lockDetails={lockDetails} />
            </Grid>

            <DSSeparatorV2 m="m 0" />

            <h3>Extension Information</h3>

            <Grid>
              <Grid
                cols={{
                  small: [1 / 1, 0, 1 / 1],
                  medium: ["275px", "auto", "1fr"],
                }}
              >
                <Grid>
                  <CalculatedExtensionDetails
                    lockDetails={lockDetails}
                    lockDateCalculation={lockDateCalculation}
                  />
                </Grid>
                <DSSeparatorV2 m="0 s" isDotted isVertical />

                <Grid>
                  {extensionFormOptions && (
                    <ExtensionForm
                      controlOptions={extensionFormOptions}
                      extensionLockDays={extensionLockDays}
                      setExtensionLockDays={setExtensionLockDays}
                      comments={comments}
                      setComments={setComments}
                      adjustmentOverride={adjustmentOverride}
                      setAdjustmentOverride={setAdjustmentOverride}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <FooterButtons buttons={footerButtons} />
            </Grid>
          </StyledEppsWrapper>
        </StyledEppsContainerBody>
      </StyledEppsParentView>
    </>
  );
};

export default LockExtension;
